import { Component, Vue } from 'vue-property-decorator';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';

@Component<AbstractMoreInfoDialog>({})
export default class AbstractMoreInfoDialog extends Vue {
  protected activeMoreInfo: MoreInfo = {
    title: 'Meer info',
    description: 'Deze uitleg ontbreekt nog...',
  };

  protected moreInfo: MoreInfo[] = [];

  protected isReadingMoreInfo = false;

  protected openReadMoreDialog(key: string) {
    const activeMoreInfo = this.moreInfo.find((moreInfo: any) => moreInfo.key === key);
    if (activeMoreInfo) {
      this.activeMoreInfo = activeMoreInfo;
    }

    this.isReadingMoreInfo = true;
  }
}
