export const getKpiClass = (amount: number, warningAt = 5, alertAt = 10, inverted = false) => {
  if (inverted) {
    if (amount < alertAt) {
      return 'rejected';
    }
    if (amount < warningAt) {
      return 'almost_ok';
    }

    return '';
  }

  if (amount > alertAt) {
    return 'rejected';
  }
  if (amount > warningAt) {
    return 'almost_ok';
  }

  return '';
};
