import { SortOptions } from '@/models/Model';
import { DashboardSchadebepalingInfo } from '@/support/Info';
import { Component } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { Rpc } from '@/models/Rpc';
import ErrorHandler from '@/support/ErrorHandler';
import { getStatusLabel } from '@/support/ReportStatus';
import { getKpiClass } from '@/support/Dashboard';
import { ReportFilters } from '@/models/UserFilter';
import { debounce } from 'lodash';
import PeriodFilter from '@/components/filters/period-filter/PeriodFilter.vue';
import { DateFormatter } from '@/support/DateFormatter';
import { User, userTypes } from '@/models/User';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';
import { DateTime } from 'luxon';

@Component<Dashboard>({
  components: {
    PeriodFilter,
  },
})
export default class Dashboard extends AbstractMoreInfoDialog {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

  public $pageTitle = 'Dashboard';

  protected isLoadingStatisctics = true;

  protected isLoadingDamageControler = true;

  protected statistics: any;

  protected sort: SortOptions | null = null;

  protected moreInfo: MoreInfo[] = DashboardSchadebepalingInfo;

  // START: Filters
  protected isShowingFilters = false;

  protected filtersHeight = 0;

  protected tableFilters: ReportFilters = {};

  protected tableSearchInput = '';

  protected submittedDate = new DateFormatter();

  protected toDate = '';

  protected fromDate = '';

  // Damage Controller
  protected damageControllers = {};

  // Users
  protected users: User[] = [];

  protected userSearch = '';

  protected debouncedSearchUsers: Function = debounce(this.handleSearchUsers, 300);

  protected userTypes = userTypes;

  protected selectedUserTypes: string[] = [];

  protected breakdowns = [
    {
      key: 'reports',
      name: 'Dossiers',
    },
    {
      key: 'damages',
      name: 'Schades',
    },
  ];

  // #endregion

  // #region Lifecycle Hooks / Init

  protected mounted() {
    this.toDate = DateTime.local().toFormat('yyyy-LL-dd');
    this.fromDate = DateTime.local().toFormat('yyyy-LL-dd');
    this.initBreadcrumb();
    this.initialize();
  }

  protected async initialize() {
    await this.listUsers();
    await this.fetchStatistics();
    await this.fetchDamageController();
  }
  // #endregion

  // #region Class methods

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Dashboard' },
        ],
      });
  }

  protected getStatusLabel(status: string) {
    if (! status) { return ''; }
    return getStatusLabel(status);
  }

  protected getKpiClass(amount: number, warningAt = 5, alertAt = 10, inverted = false) {
    return getKpiClass(amount, warningAt, alertAt, inverted);
  }

  protected handleSearchUsers(query: any) {
    if (! query) {
      return;
    }

    this.listUsers();
  }

  protected applyStatisticsDateFilters(from: string, to: string) {
    this.tableFilters.submittedFrom = from;
    this.tableFilters.submittedTo = to;
    this.fetchStatistics();
  }

  protected applyDateFilters(from: string, to: string) {
    this.tableFilters.from = from;
    this.tableFilters.to = to;
  }

  public sortDamageController(sortable: SortOptions, index: number) {
    if (! sortable) {
      return;
    }

    this.damageControllerHeaders.forEach((sort: SortOptions) => {
      if (sort.key !== sortable.key) {
        sort.order = 'DESC';
      }
    });

    if (sortable.order === 'ASC') {
      sortable.order = 'DESC';
    } else {
      sortable.order = 'ASC';
    }

    this.$set(this.damageControllerHeaders[index], 'sortable', sortable);

    this.sort = sortable;
    this.fetchDamageController();
  }

  public isSortable(header: any) {
    if (! header.sortable) {
      return false;
    }
    return true;
  }

  protected handleApplyFilter(filters: any) {
    this.toDate = filters.to;
    this.fromDate = filters.from;
    this.tableFilters = filters;

    this.fetchDamageController();
    this.fetchStatistics();
  }
  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */

  protected async fetchStatistics() {
    this.isLoadingStatisctics = true;
    const payload = {
      signature: 'management-reports:sba-dashboard',
      body: {
        period: {},
      } as StatisticsPayloadBody,
    };

    // if (this.tableFilters.submittedFrom?.length) { payload.body.period.from = this.tableFilters.submittedFrom; }
    // if (this.tableFilters.submittedTo?.length) { payload.body.period.to = this.tableFilters.submittedTo; }
    if (this.tableFilters.organizations?.length) { payload.body.organizations = this.tableFilters.organizations; }
    if (this.tableFilters.tags?.length) { payload.body.tags = this.tableFilters.tags; }
    if (this.tableFilters.types?.length) { payload.body.types = this.tableFilters.types; }
    if (this.tableFilters.report_handlers?.length) { payload.body.report_handlers = this.tableFilters.report_handlers; }
    if (this.tableFilters.breakdown?.length) { payload.body.breakdown = this.tableFilters.breakdown; }

    const response = await new Rpc()
      .rpcPost(payload, false)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.statistics = response.data;
    this.isLoadingStatisctics = false;
  }

  protected async fetchDamageController() {
    this.isLoadingDamageControler = true;

    const payload = {
      signature: 'production-statistics:damage-controller',
      body: {
        in_active: this.tableFilters.in_active,
        period: {},
      } as DamageControllerPayloadBody,
    };

    if (this.tableFilters.from?.length) { payload.body.period.from = this.tableFilters.from; }
    if (this.tableFilters.to?.length) { payload.body.period.to = this.tableFilters.to; }
    if (this.tableFilters.users?.length) { payload.body.users = this.tableFilters.users; }
    if (this.tableFilters.user_types?.length) { payload.body.user_types = this.tableFilters.user_types; }
    // if (this.tableFilters.breakdown?.length) { payload.body.breakdown = this.tableFilters.breakdown; }

    if (this.tableFilters.organizationsDamageController?.length) { payload.body.organizations = this.tableFilters.organizationsDamageController; }
    if (this.tableFilters.tagsDamageController?.length) { payload.body.tags = this.tableFilters.tagsDamageController; }
    if (this.tableFilters.typesDamageController?.length) { payload.body.types = this.tableFilters.typesDamageController; }

    if (this.sort && this.sort.key) {
      payload.body.sort = this.sort.order === 'DESC' ? `!${this.sort.key}` : this.sort.key;
    }

    const response = await new Rpc()
      .rpcPost(payload, false);
    this.damageControllers = response.data;

    this.isLoadingDamageControler = false;
  }

  protected async listUsers(): Promise<void> {
    this.users = await new User()
      .filter({ search: this.userSearch })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }
  // #endregion

  // #region Getters & Setters

  protected get damageControllerHeaders() {
    return [
      {
        name: 'Naam',
        key: 'name',
      },
      {
        name: 'Verstuurd <br> naar aanvrager',
        key: 'downloaded',
        sortable: {
          order: 'DESC',
        },
      },
      {
        name: 'Afgekeurd <br> Schadebepaling',
        key: 'veldwerk_rejected',
        sortable: {
          order: 'DESC',
        },
      },
      {
        name: 'Percentage <br> akkoord/niet akkoord',
        key: 'percentage',
        sortable: {
          order: 'DESC',
        },
      },
      {
        name: 'On Hold <br> Schadebepaling',
        key: 'veldwerk_onhold',
        sortable: {
          order: 'DESC',
        },
      },
      {
        name: 'Controle JZ',
        key: 'specials_in_review',
        sortable: {
          order: 'DESC',
        },
      },
      {
        name: 'Totaal',
        key: 'totals',
        sortable: {
          order: 'DESC',
        },
      },
    ];
  }
  // #endregion

  // #region @Watchers

  // #endregion
}

interface StatisticsPayloadBody {
  period: {
    from?: string;
    to?: string;
  };
  report_handlers?: string[];
  organizations?: string[];
  types?: string[];
  tags?: string[];
  breakdown?: string;
}

interface DamageControllerPayloadBody {
  organizations?: string[];
  types?: string[];
  tags?: string[];
  breakdown?: string;
  period: {
    from?: string;
    to?: string;
  };
  users?: string[];
  user_types?: string[];
  sort?: string;
}
