var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [_vm._v("Dashboard")])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel" },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "", "justify-space-between": "" } },
                  [
                    _c("v-flex", [
                      _c("h2", { staticClass: "elementSubTitle" }, [
                        _vm._v("Huidige werkvoorraad")
                      ])
                    ]),
                    _c(
                      "v-flex",
                      {
                        staticClass: "custom-filter--userFilterReports",
                        attrs: { shrink: "" }
                      },
                      [
                        _c("UserFilter", {
                          ref: "userFilter",
                          attrs: {
                            filterType: "schadebepaling-statistics-dashboard",
                            filters: _vm.tableFilters
                          },
                          on: { selectedUserFilters: _vm.handleApplyFilter }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  { attrs: { "align-center": "", row: "", wrap: "" } },
                  [
                    _vm.$store.state.isServiceOrganization
                      ? _c(
                          "v-flex",
                          {
                            staticClass:
                              "custom-filter custom-filter--autocomplete",
                            attrs: { shrink: "" }
                          },
                          [
                            _c("ReportOrganizationFilter", {
                              on: {
                                handleChange: function($event) {
                                  return _vm.fetchStatistics()
                                }
                              },
                              model: {
                                value: _vm.tableFilters.organizations,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.tableFilters,
                                    "organizations",
                                    $$v
                                  )
                                },
                                expression: "tableFilters.organizations"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$store.state.isServiceOrganization
                      ? _c(
                          "v-flex",
                          {
                            staticClass:
                              "custom-filter custom-filter--autocomplete",
                            attrs: { shrink: "" }
                          },
                          [
                            _c("ReportHandlerFilter", {
                              on: { handleChange: _vm.fetchStatistics },
                              model: {
                                value: _vm.tableFilters.report_handlers,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.tableFilters,
                                    "report_handlers",
                                    $$v
                                  )
                                },
                                expression: "tableFilters.report_handlers"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-flex",
                      {
                        staticClass:
                          "custom-filter custom-filter--autocomplete",
                        attrs: { shrink: "" }
                      },
                      [
                        _c("ReportTypeFilter", {
                          on: {
                            handleChange: function($event) {
                              return _vm.fetchStatistics()
                            }
                          },
                          model: {
                            value: _vm.tableFilters.types,
                            callback: function($$v) {
                              _vm.$set(_vm.tableFilters, "types", $$v)
                            },
                            expression: "tableFilters.types"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      {
                        staticClass:
                          "custom-filter custom-filter--autocomplete",
                        attrs: { shrink: "" }
                      },
                      [
                        _c("ReportTagFilter", {
                          on: {
                            handleChange: function($event) {
                              return _vm.fetchStatistics()
                            }
                          },
                          model: {
                            value: _vm.tableFilters.tags,
                            callback: function($$v) {
                              _vm.$set(_vm.tableFilters, "tags", $$v)
                            },
                            expression: "tableFilters.tags"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      {
                        staticClass:
                          "custom-filter custom-filter--autocomplete",
                        attrs: { shrink: "" }
                      },
                      [
                        _c("v-autocomplete", {
                          staticClass: "with-full-border",
                          attrs: {
                            items: _vm.breakdowns,
                            "item-value": "key",
                            "item-text": "name",
                            "hide-details": "",
                            placeholder: "Breakdown",
                            clearable: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.fetchStatistics()
                            }
                          },
                          model: {
                            value: _vm.tableFilters.breakdown,
                            callback: function($$v) {
                              _vm.$set(_vm.tableFilters, "breakdown", $$v)
                            },
                            expression: "tableFilters.breakdown"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c("v-flex", { attrs: { xs12: "" } }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "elementPanel elementPanel--spaced"
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "text-center": "" } },
                                    [
                                      _vm.isLoadingStatisctics
                                        ? _c("LoaderCard", {
                                            attrs: {
                                              flat: "",
                                              type: "spinner--center"
                                            }
                                          })
                                        : _vm._e(),
                                      !_vm.isLoadingStatisctics
                                        ? [
                                            _c(
                                              "v-layout",
                                              { attrs: { wrap: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        attrs: {
                                                          wrap: "",
                                                          "justify-space-between":
                                                            ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "info-icon",
                                                            attrs: {
                                                              shrink: ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.openReadMoreDialog(
                                                                                      "rapport_aantallen"
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " info_outline "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  235925534
                                                                )
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "klik voor meer info"
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: { xs6: "", md4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to:
                                                            "/reports?status=approved"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "StatisticsNumberWidget",
                                                          {
                                                            attrs: {
                                                              item: {
                                                                class: [
                                                                  _vm.getKpiClass(
                                                                    _vm
                                                                      .statistics
                                                                      .status_totals[
                                                                      "approved"
                                                                    ]
                                                                  )
                                                                ],
                                                                amount:
                                                                  _vm.statistics
                                                                    .status_totals
                                                                    .approved,
                                                                description: _vm.getStatusLabel(
                                                                  "approved"
                                                                )
                                                              }
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: { xs6: "", md4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to:
                                                            "/reports?status=veldwerk_corrected"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "StatisticsNumberWidget",
                                                          {
                                                            attrs: {
                                                              item: {
                                                                class: [
                                                                  _vm.getKpiClass(
                                                                    _vm
                                                                      .statistics
                                                                      .status_totals[
                                                                      "veldwerk_corrected"
                                                                    ]
                                                                  )
                                                                ],
                                                                amount:
                                                                  _vm.statistics
                                                                    .status_totals
                                                                    .veldwerk_corrected,
                                                                description: _vm.getStatusLabel(
                                                                  "veldwerk_corrected"
                                                                )
                                                              }
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: { xs6: "", md4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to:
                                                            "/reports?status=pending_sb_approval"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "StatisticsNumberWidget",
                                                          {
                                                            attrs: {
                                                              item: {
                                                                class: [
                                                                  _vm.getKpiClass(
                                                                    _vm
                                                                      .statistics
                                                                      .status_totals[
                                                                      "pending_sb_approval"
                                                                    ]
                                                                  )
                                                                ],
                                                                amount:
                                                                  _vm.statistics
                                                                    .status_totals
                                                                    .pending_sb_approval,
                                                                description: _vm.getStatusLabel(
                                                                  "pending_sb_approval"
                                                                )
                                                              }
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { wrap: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: { xs6: "", md4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to:
                                                            "/reports?status=specials_reviewed"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "StatisticsNumberWidget",
                                                          {
                                                            attrs: {
                                                              item: {
                                                                class: [
                                                                  _vm.getKpiClass(
                                                                    _vm
                                                                      .statistics
                                                                      .status_totals[
                                                                      "specials_reviewed"
                                                                    ]
                                                                  )
                                                                ],
                                                                amount:
                                                                  _vm.statistics
                                                                    .status_totals
                                                                    .specials_reviewed,
                                                                description: _vm.getStatusLabel(
                                                                  "specials_reviewed"
                                                                )
                                                              }
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: { xs6: "", md4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to:
                                                            "/reports?status=specials_in_review"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "StatisticsNumberWidget",
                                                          {
                                                            attrs: {
                                                              item: {
                                                                class: [
                                                                  _vm.getKpiClass(
                                                                    _vm
                                                                      .statistics
                                                                      .status_totals[
                                                                      "specials_in_review"
                                                                    ]
                                                                  )
                                                                ],
                                                                amount:
                                                                  _vm.statistics
                                                                    .status_totals
                                                                    .specials_in_review,
                                                                description: _vm.getStatusLabel(
                                                                  "specials_in_review"
                                                                )
                                                              }
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: { xs6: "", md4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to:
                                                            "/reports?status=veldwerk_onhold"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "StatisticsNumberWidget",
                                                          {
                                                            attrs: {
                                                              item: {
                                                                class: [
                                                                  _vm.getKpiClass(
                                                                    _vm
                                                                      .statistics
                                                                      .status_totals[
                                                                      "veldwerk_onhold"
                                                                    ]
                                                                  )
                                                                ],
                                                                amount:
                                                                  _vm.statistics
                                                                    .status_totals
                                                                    .veldwerk_onhold,
                                                                description: _vm.getStatusLabel(
                                                                  "veldwerk_onhold"
                                                                )
                                                              }
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "mb-3": "" } },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: { "align-center": "", row: "", wrap: "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs4: "" } },
                                  [
                                    _c("PeriodFilter", {
                                      attrs: {
                                        to: _vm.toDate,
                                        from: _vm.fromDate
                                      },
                                      on: { change: _vm.applyDateFilters }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass:
                                      "custom-filter custom-filter--autocomplete",
                                    attrs: { shrink: "" }
                                  },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "with-full-border",
                                      attrs: {
                                        items: _vm.users,
                                        "item-text": "name",
                                        "item-value": "id",
                                        "hide-details": "",
                                        placeholder: "Medewerkers",
                                        "search-input": _vm.userSearch,
                                        clearable: "",
                                        multiple: ""
                                      },
                                      on: {
                                        keyup: _vm.debouncedSearchUsers,
                                        "update:searchInput": function($event) {
                                          _vm.userSearch = $event
                                        },
                                        "update:search-input": function(
                                          $event
                                        ) {
                                          _vm.userSearch = $event
                                        },
                                        change: _vm.fetchDamageController
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "selection",
                                          fn: function(ref) {
                                            var item = ref.item
                                            var index = ref.index
                                            return [
                                              index === 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Medewerker (" +
                                                        _vm._s(
                                                          _vm.tableFilters.users
                                                            .length
                                                        ) +
                                                        ")"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.tableFilters.users,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.tableFilters,
                                            "users",
                                            $$v
                                          )
                                        },
                                        expression: "tableFilters.users"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass:
                                      "custom-filter custom-filter--autocomplete",
                                    attrs: { shrink: "" }
                                  },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "with-full-border",
                                      attrs: {
                                        items: _vm.userTypes,
                                        "item-value": "key",
                                        "item-text": "name",
                                        "hide-details": "",
                                        placeholder: "Medewerker Type",
                                        clearable: "",
                                        multiple: ""
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "selection",
                                          fn: function(ref) {
                                            var item = ref.item
                                            var index = ref.index
                                            return [
                                              index === 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Medewerker Type (" +
                                                        _vm._s(
                                                          _vm.tableFilters
                                                            .user_types.length
                                                        ) +
                                                        ")"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.tableFilters.user_types,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.tableFilters,
                                            "user_types",
                                            $$v
                                          )
                                        },
                                        expression: "tableFilters.user_types"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass:
                                      "custom-filter custom-filter--autocomplete",
                                    attrs: { shrink: "" }
                                  },
                                  [
                                    _c("ReportTypeFilter", {
                                      model: {
                                        value:
                                          _vm.tableFilters
                                            .typesDamageController,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.tableFilters,
                                            "typesDamageController",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "tableFilters.typesDamageController"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass:
                                      "custom-filter custom-filter--autocomplete",
                                    attrs: { shrink: "" }
                                  },
                                  [
                                    _c("ReportTagFilter", {
                                      model: {
                                        value:
                                          _vm.tableFilters.tagsDamageController,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.tableFilters,
                                            "tagsDamageController",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "tableFilters.tagsDamageController"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.$store.state.isServiceOrganization
                                  ? _c(
                                      "v-flex",
                                      {
                                        staticClass:
                                          "custom-filter custom-filter--autocomplete",
                                        attrs: { shrink: "" }
                                      },
                                      [
                                        _c("ReportOrganizationFilter", {
                                          model: {
                                            value:
                                              _vm.tableFilters
                                                .organizationsDamageController,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.tableFilters,
                                                "organizationsDamageController",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tableFilters.organizationsDamageController"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        label:
                                          "Verberg alle inactieve gebruiker",
                                        "hide-details": ""
                                      },
                                      model: {
                                        value: _vm.tableFilters.in_active,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.tableFilters,
                                            "in_active",
                                            $$v
                                          )
                                        },
                                        expression: "tableFilters.in_active"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "text-xs-right",
                                    attrs: { sm12: "" }
                                  },
                                  [
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.isLoadingDamageControler,
                                          color: "primary",
                                          small: "true",
                                          outline: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.fetchDamageController()
                                          }
                                        }
                                      },
                                      [_vm._v(" Pas filters toe ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.isLoadingDamageControler
                          ? _c("LoaderCard", {
                              attrs: { flat: "", type: "spinner--center" }
                            })
                          : _vm._e(),
                        !_vm.isLoadingDamageControler
                          ? _c(
                              "table",
                              {
                                staticClass: "item__list item__list--table",
                                attrs: { wrap: "" }
                              },
                              [
                                _c("thead", [
                                  _c(
                                    "tr",
                                    {
                                      staticClass: "label header__row",
                                      attrs: {
                                        wrap: "",
                                        row: "",
                                        "align-center": ""
                                      }
                                    },
                                    [
                                      _vm._l(
                                        _vm.damageControllerHeaders,
                                        function(header, index) {
                                          return [
                                            _c(
                                              "td",
                                              {
                                                key: "header-" + index,
                                                class: _vm.isSortable(header)
                                                  ? "sorting__item"
                                                  : ""
                                              },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      header.name
                                                    )
                                                  }
                                                }),
                                                _vm.isSortable(header)
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "sorting__arrow",
                                                        class: {
                                                          active:
                                                            header.sortable
                                                              .order === "ASC"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.sortDamageController(
                                                              header,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" expand_more ")]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ]),
                                _c(
                                  "tbody",
                                  [
                                    _vm._l(_vm.damageControllers.data, function(
                                      damageController,
                                      index
                                    ) {
                                      return _c(
                                        "tr",
                                        {
                                          key: "damageController-" + index,
                                          staticClass: "list__item"
                                        },
                                        [
                                          _c("td", {}, [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(damageController.name)
                                              )
                                            ])
                                          ]),
                                          _c("td", {}, [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  damageController.downloaded
                                                )
                                              )
                                            ])
                                          ]),
                                          _c("td", {}, [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  damageController.veldwerk_rejected
                                                )
                                              )
                                            ])
                                          ]),
                                          _c("td", {}, [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  damageController.percentage
                                                ) + "%"
                                              )
                                            ])
                                          ]),
                                          _c("td", {}, [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  damageController.veldwerk_onhold
                                                )
                                              )
                                            ])
                                          ]),
                                          _c("td", {}, [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  damageController.specials_in_review
                                                )
                                              )
                                            ])
                                          ]),
                                          _c("td", {}, [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(damageController.totals)
                                              )
                                            ])
                                          ])
                                        ]
                                      )
                                    }),
                                    _vm.damageControllers.totals
                                      ? _c(
                                          "tr",
                                          { staticClass: "list__item" },
                                          [
                                            _c("td", {}, [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.damageControllers
                                                        .totals.name
                                                    )
                                                  )
                                                ]
                                              )
                                            ]),
                                            _c("td", {}, [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.damageControllers
                                                        .totals.downloaded
                                                    )
                                                  )
                                                ]
                                              )
                                            ]),
                                            _c("td", {}, [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.damageControllers
                                                        .totals
                                                        .veldwerk_rejected
                                                    )
                                                  )
                                                ]
                                              )
                                            ]),
                                            _c("td", {}, [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.damageControllers
                                                        .totals.percentage
                                                    ) + "%"
                                                  )
                                                ]
                                              )
                                            ]),
                                            _c("td", {}, [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.damageControllers
                                                        .totals.veldwerk_onhold
                                                    )
                                                  )
                                                ]
                                              )
                                            ]),
                                            _c("td", {}, [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.damageControllers
                                                        .totals
                                                        .specials_in_review
                                                    )
                                                  )
                                                ]
                                              )
                                            ]),
                                            _c("td", {}, [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.damageControllers
                                                        .totals.totals
                                                    )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }